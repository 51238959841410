import './LeftBar.css';

import { Button } from '@material-ui/core';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
// import addUser from "../../../../assets/icons/web-app-R8tr/Area of expertise/add-user.png";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Typography } from '@mui/material';
import { api } from 'api/apiClient';
import { useContext, useEffect, useState } from 'react';
// import { SwitchToBuyerOrR8trAction } from "../../../../redux/actions/LogInAction";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import useLogout from 'redux/actions/useLogout';

import packageJson from '../../../../../package.json';
import { ApiUrl } from '../../../../ApiUrl';
import support from '../../../../assets/icons/web-app-R8tr/Area of expertise/support.webp';
import sideNavLogo from '../../../../assets/images/left_bar/sideNavLog.webp';
import logo from '../../../../assets/images/logo.webp';
import money from '../../../../assets/images/topbar_icons/save_money.webp';
import star from '../../../../assets/images/topbar_icons/star.webp';
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
import { getUserData } from '../../../../redux/actions/UserAction';
import SendEmail from '../../../SendEmail/SendEmail';
import { LeftBarStyle } from '../leftBar/LeftBarStyle';
import ProfileClickedContext from './../../../../utils/context';
import {
  buyerItem,
  buyerProfileData,
  r8trItem,
  r8trProfileData,
} from './LeftBarData';

const LeftBarDesign = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const classes = LeftBarStyle();
  const [pathname, setPathname] = useState('/');
  // const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const userRole = localStorage.getItem('userRole');
  const profileClickedContext = useContext(ProfileClickedContext);
  const { userDetails } = useSelector((state) => state.UserReducer);
  const { dashboardData } = useSelector(
    (state) => state.UserDashboardStatReducer
  );

  const logout = useLogout();

  const appVersion = packageJson.version;

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const handleToken = async () => {
    api.post(`${ApiUrl.server_url}v1/auth/logout`, {
      deviceType: 'web',
      userId: userDetails?.data?.user?.id,
    });

    logout();
  };

  const handleProfileClick = () => {
    if (localStorage.getItem('userRole') == 'r8tr') {
      if (userDetails?.data?.user?.phoneNumber) {
        if (
          userDetails.data?.user?.firstName == null ||
          userDetails.data?.user?.firstName == ''
        ) {
          navigator('/personal-info', {
            state: { massage: 'Please Complete Personal Info' },
          });
        } else if (userDetails.data?.areas.length < 1) {
          navigator('/service-area', {
            state: { massage: 'Please Complete Service Area' },
          });
        } else if (userDetails.data?.levelOfExpertise.length < 1) {
          navigator('/area-of-expertise', {
            state: { massage: 'Please Complete Area of Expertise' },
          });
        } else {
          navigator('/');
        }
      }
    } else {
      navigator('/');
    }
  };

  const handleSendEmailModal = () => {
    setOpen(true);
  };

  const handleCloseSendEmailModal = () => {
    setOpen(false);
  };

  return (
    <div className="leftbar_main_container">
      <div className={classes.logoDiv}>
        <img
          alt="logo"
          src={logo}
          height="35"
          width="70"
          onClick={() => {
            handleProfileClick();
          }}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className={classes.RatingContent}>
        {localStorage.getItem('userRole') == 'r8tr' ? (
          <>
            <div className="rating_avatar ">
              <img
                src={money}
                alt="money"
                className="rating_avatar_money_icons"
              />
            </div>
            <span>
              <p className="rating_avatar_icons_p Font_Family theme_text_color fs-bold">
                TOTAL EARNED
                <br />
                <span className="theme-color">
                  $
                  {dashboardData?.data?.totalEarned
                    ? dashboardData?.data?.totalEarned.toFixed(2)
                    : 0}
                </span>
              </p>
            </span>
            <div className="rating_avatar ">
              <img src={star} alt="star" className="" />
            </div>
            <span>
              <p className="rating_avatar_icons_p Font_Family theme_text_color">
                MY RATING
                <br />
                <span className="theme-color">
                  {dashboardData?.data?.totalRating
                    ? dashboardData?.data?.totalRating?.toFixed(1)
                    : '0'}
                </span>
              </p>
            </span>
          </>
        ) : (
          <>
            <div className="rating_avatar_buyer ">
              <img
                src={money}
                alt="money"
                className="rating_avatar_money_icons"
              />
            </div>
            <span>
              <p className="rating_avatar_icons_p Font_Family theme_text_color">
                {/* <b> */}
                COMPLETED RATINGS
                <br />{' '}
                <span className="theme-color">
                  {dashboardData?.data?.ratingsCompleted
                    ? dashboardData?.data?.ratingsCompleted.toFixed(2)
                    : 0}
                </span>
                {/* </b> */}
              </p>
              {/* <p>$44.4</p> */}
            </span>
            <div className="rating_avatar_buyer ">
              <img src={star} alt="star" className="" />
            </div>
            <span>
              <p className="rating_avatar_icons_p Font_Family theme_text_color">
                {/* <b> */}
                CURRENT RATING
                <br />
                <span className="theme-color">
                  {dashboardData?.data?.currentBuyerRating
                    ? dashboardData?.data?.currentBuyerRating.toFixed(2)
                    : '0'}
                </span>
                {/* </b> */}
              </p>
            </span>
          </>
        )}
      </div>
      <div style={{ width: '100%' }}>
        {profileClickedContext.profileClicked == true ? (
          <>
            {userRole === 'buyer' ? (
              <>
                {buyerProfileData.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                  >
                    {pathname === item.path ? (
                      <div className="icon_link">{item.iconFilled}</div>
                    ) : (
                      <div className="icon_link">{item.icon}</div>
                    )}
                    <div className="lefbar_link_text">{item.name}</div>
                  </NavLink>
                ))}
              </>
            ) : (
              r8trProfileData.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  {pathname === item.path ? (
                    <div className="icon_link">{item.iconFilled}</div>
                  ) : (
                    <div className="icon_link">{item.icon}</div>
                  )}
                  <div className="lefbar_link_text">{item.name}</div>
                </NavLink>
              ))
            )}
          </>
        ) : (
          <>
            {userRole === 'buyer' ? (
              <>
                {buyerItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassname="active"
                  >
                    {pathname === item.path ? (
                      <div className="icon_link">{item.iconFilled}</div>
                    ) : (
                      <div className="icon_link">{item.icon}</div>
                    )}
                    <div className="lefbar_link_text">{item.name}</div>
                  </NavLink>
                ))}
                {/* <NavLink className="link" activeclassname="active"> */}
                {/* {pathname === item.path ? ( */}
                {!user?.user?.parentUserId && (
                  <div
                    onClick={handleSendEmailModal}
                    className={
                      open ? 'active_invite_link_tab' : 'invite_link_tab'
                    }
                  >
                    {open ? (
                      <div>
                        <GroupAddIcon
                          height="20px"
                          width="20px"
                          sx={{
                            color: 'white !important',
                          }}
                        />
                      </div>
                    ) : (
                      <div>
                        <GroupAddOutlinedIcon
                          height="20px"
                          width="20px"
                          sx={{
                            color: 'gray !important',
                          }}
                        />
                      </div>
                    )}
                    &nbsp; &nbsp;
                    <div
                      className={
                        open
                          ? 'active_invite_link_tab_text'
                          : 'invite_link_tab_text'
                      }
                    >
                      INVITE
                    </div>
                  </div>
                )}

                {/* </NavLink> */}
              </>
            ) : (
              r8trItem.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  {pathname === item.path ? (
                    <div className="icon_link">{item.iconFilled}</div>
                  ) : (
                    <div className="icon_link">{item.icon}</div>
                  )}
                  <div className="lefbar_link_text">{item.name}</div>
                </NavLink>
              ))
            )}
          </>
        )}
      </div>
      {/* <div className="sideNavLogoDiv"> */}
      <img
        src={sideNavLogo}
        alt="logo"
        width="85%"
        className="lefbar_eight_logo"
        // height="250"
        style={{
          objectFit: 'cover',
          marginTop:
            profileClickedContext.profileClicked == true ? '10px' : '30px',
        }}
      />

      {/* </div> */}
      <div className={classes.bottomSideNav}>
        <Typography
          m="24px"
          color="darkgray"
        >{`Version ${appVersion}`}</Typography>
        <div className={classes.spanSideNav}>
          <span
            onClick={() => {
              navigator('/support');
            }}
            className={classes.supportSpan}
          >
            <img src={support} alt="support" height="20px" width="20px" />
            SUPPORT
          </span>

          {/* {!user?.user?.parentUserId ? (
            <span onClick={handleSwitchAccount} className={classes.switchSpan}>
              <img
                src={addUser}
                alt="switch account"
                height="20px"
                width="20px"
              />
              {userRole === "buyer" ? "SWITCH TO R8TR" : "SWITCH TO BUYER"}
            </span>
          ) : (
            <div
              style={{
                width: "30%",
              }}
            ></div>
          )} */}
        </div>
        <Button
          onClick={handleToken}
          style={{ backgroundColor: '#d40000', height: '50px', color: 'white' }}
          className="rounded-0 w-100"
        >
          <PowerSettingsNewIcon /> &nbsp; SIGN OUT
        </Button>
      </div>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {open && (
        <SendEmail
          handleSendEmailModal={handleSendEmailModal}
          open={open}
          handleCloseSendEmailModal={handleCloseSendEmailModal}
        />
      )}
    </div>
  );
};

export default LeftBarDesign;
