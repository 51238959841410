import './notification.css';

import { Button } from '@mui/material';
import apiProtected from 'api/apiClient';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ApiUrl } from '../../../ApiUrl';
import close from '../../../assets/images/notification/close-icon.webp';
import { R8trNotificationAction } from '../../../redux/actions/R8tr/R8trNotificationAction';
import * as SweetAlert from '../../SweetAlert';

function Notification() {
  const dispatch = useDispatch();
  // const [deleteId, setDeleteId] = useState("");
  const { NotificationData } = useSelector(
    (state) => state.R8trNotificationReducer
  );
  useEffect(() => {
    dispatch(R8trNotificationAction());
  }, []);

  const handleNotificationDelete = (id) => {
    apiProtected
      .delete(`${ApiUrl.server_url}v1/notifications`, {
        params: { notificationId: id },
      })
      .then(() => {
        dispatch(R8trNotificationAction());
        SweetAlert.successMessage('Notification Deleted Successfully');
      });
  };

  const handleNotificationDeleteAlert = (id) => {
    SweetAlert.altMessage(handleNotificationDelete, id);
  };

  const handleNotificationDeleteAll = () => {
    SweetAlert.altMessage(deleteAllNotifications);
  };

  const deleteAllNotifications = () => {
    apiProtected
      .delete(`${ApiUrl.server_url}v1/notifications/delete-all-notifications`)
      .then(() => {
        dispatch(R8trNotificationAction());
        SweetAlert.successMessage('All Notifications Deleted Successfully');
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <Container className="notification_main_container">
        <Row className="notification_row_container">
          <Col xxl={10} xl={10} lg={10} md={10} sm={11} xs={11}>
            <div className="d-flex justify-content-end mb-4">
              <Button
                color="error"
                variant="contained"
                className="btn Font_Family rounded"
                style={{
                  height: '45px',
                  color: 'white',
                }}
                onClick={handleNotificationDeleteAll}
              >
                CLEAR ALL
              </Button>
            </div>
            <table className="table table-striped w-100">
              {NotificationData?.length ? (
                <tbody style={{ width: '100%' }}>
                  {NotificationData.map((item) => (
                    <tr
                      key={item.id}
                      style={{ width: '100%' }}
                      className="notification_tr"
                    >
                      <th scope="row" className="table_data">
                        <p
                          className="Font_Family text-uppercase"
                          style={{ fontSize: '14px' }}
                        >
                          {item.title}
                          <br />
                          <span
                            style={{ fontSize: '10px' }}
                            className="silver_Color text-uppercase"
                          >
                            {item.body}
                          </span>
                        </p>
                        <img
                          src={close}
                          alt="close"
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleNotificationDeleteAlert(item.id)}
                        />
                      </th>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody style={{ width: '100%' }}>
                  <th scope="row" className="notification_main_container">
                    <p
                      className="Font_Family text-uppercase"
                      style={{ fontSize: '14px' }}
                    >
                      No Notification Found
                    </p>
                  </th>
                </tbody>
              )}
            </table>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Notification;
