import './sweetalert.css';

import {
  default as Swal,
  default as swalWithBootstrapButtons,
} from 'sweetalert2';
// import Swal from "sweetalert2/dist/sweetalert2.js";
export const successMessage = (message) => {
  Swal.fire('Successfull!', message, 'success');
};
export const errorMessage = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: message,
  });
};

export const altMessage = (action, params) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        action(params);
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   "Cancelled",
        //   "Your imaginary file is safe :)",
        //   "error"
        // );
      }
    });
};

export const deleteImageAlert = (handleimageDelete, func, index) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Do you really want to delete this image ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleimageDelete(func, index);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
      {
        // swalWithBootstrapButtons.fire(
        //   "Cancelled",
        //   "Your imaginary file is safe :)",
        //   "error"
        // );
      }
    });
};

export const deleteVideoAlert = (handleDeleteVideosRes, index) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Do you really want to delete this Video ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleDeleteVideosRes(index);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
      {
        // swalWithBootstrapButtons.fire(
        //   "Cancelled",
        //   "Your imaginary file is safe :)",
        //   "error"
        // );
      }
    });
};
export const deleteAccountAlert = (handleDeleteAccountRes) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Do you really want to delete Your Account ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleDeleteAccountRes();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
      {
        // swalWithBootstrapButtons.fire(
        //   "Cancelled",
        //   "Your imaginary file is safe :)",
        //   "error"
        // );
      }
    });
};
export const ratingsCompletedAlert = (handleContinue) => {
  swalWithBootstrapButtons
    .fire({
      title: 'Are you sure you want to continue ?',
      text: "Once you move forward, you won't be able to edit your ratings.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, continue',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-success',
      },
      buttonsStyling: true,
    })
    .then((result) => {
      if (result.isConfirmed) {
        handleContinue();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        return;
      }
      {
        // swalWithBootstrapButtons.fire(
        //   "Cancelled",
        //   "Your imaginary file is safe :)",
        //   "error"
        // );
      }
    });
};

export const CancelJobsAlert = (handleCancelJobsConfirm) => {
  swalWithBootstrapButtons.fire({
    title: 'Do you really want to delete this Job ?',
    input: 'select',
    inputOptions: {
      'Inaccurate information': 'Inaccurate information',
      'Duplicate rating': 'Duplicate rating',
      'Location or Commute': 'Location or Commute',
      'Technical issues': 'Technical issues',
      Other: 'Other',
    },
    inputPlaceholder: 'Select a Reason',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, do it!',
    cancelButtonText: 'No, cancel!',
    reverseButtons: true,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-success',
    },
    buttonsStyling: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          if (value === 'Other') {
            swalWithBootstrapButtons.fire({
              title: 'Add reason',
              input: 'text',
              // inputLabel: 'Add Reason',
              confirmButtonText: 'Delete',
              cancelButtonText: 'Cancel!',
              reverseButtons: true,
              showCancelButton: true,
              showConfirmButton: true,
              inputValidator: (value) => {
                if (value) {
                  // resolve();
                  handleCancelJobsConfirm(value);
                } else {
                  return 'You need to write something!';
                }
              },
            });
          } else {
            resolve();
            handleCancelJobsConfirm(value);
          }
        } else {
          resolve('Please select one reason');
        }
      });
    },
  });
};
