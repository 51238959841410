import 'photoswipe/dist/photoswipe.css';
import './criteriaoption.css';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import apiProtected from 'api/apiClient';
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Gallery, Item } from 'react-photoswipe-gallery';
import S3FileUpload from 'react-s3';
import { toast, ToastContainer } from 'react-toastify';

import { ApiUrl } from '../../../ApiUrl';
import image_upload_icon from '../../../assets/images/image_upload_icon.png';
import Loader from '../../Loader/Loader';
import * as SweetAlert from '../../SweetAlert';
window.Buffer = window.Buffer || require('buffer').Buffer;
const toastOptions = {
  position: 'top-right',
  autoClose: 2000,
  pauseOnHover: true,
  draggable: true,
  theme: 'dark',
};
function CriteriaOption({
  ratingCriteriaOptions,
  damages,
  ratingCriteria,
  ratingCriteriaId,
  jobId,
  handleR8tingCategoryListUpdates,
  criteriaListTitle,
}) {
  const [r8tingCriteriaDamageIds, setR8tingCriteriaDamageIds] = useState([]);
  const [r8tingCriteriaOptionId, setR8tingCriteriaOptionId] = useState(null);
  const [r8tingCriteriaOptionValue, setR8tingCriteriaOptionValue] = useState();
  const [massage, setMassage] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [saveButton, setSaveButton] = useState(false);
  const onChange = (imageList) => {
    setImages(imageList);
  };
  const handleimageDelete = (func, index) => {
    func(index);
  };
  const handleDeleteOrUpdate = (func, index) => {
    SweetAlert.deleteImageAlert(handleimageDelete, func, index);
  };
  useEffect(() => {
    if (ratingCriteria.ratingCriteriaUser != null) {
      setR8tingCriteriaDamageIds(ratingCriteria.ratingCriteriaUser.damageIds);
      setR8tingCriteriaOptionId(
        ratingCriteria.ratingCriteriaUser.ratingCriteriaOptionId
      );
      setR8tingCriteriaOptionValue(
        ratingCriteria.ratingCriteriaUser.ratingValue
      );
      setImages(ratingCriteria.ratingCriteriaUser.images);
      setMassage(ratingCriteria.ratingCriteriaUser.criteriaInfo);
    }
    //  else {
    //   setR8tingCriteriaOptionId(ratingCriteriaOptions[0]?.id);
    // }
  }, [ratingCriteria]);
  const handler8tingCriteriaDamageTab = (id) => {
    let damageIds = [...r8tingCriteriaDamageIds];
    let existingDamageIdIndex = damageIds.indexOf(id);
    if (existingDamageIdIndex > -1) {
      damageIds.splice(existingDamageIdIndex, 1);
    } else {
      damageIds.push(id);
    }
    setR8tingCriteriaDamageIds(damageIds);
  };

  const handler8tingCriteriaOptionTab = (id, value) => {
    setR8tingCriteriaOptionValue(value);
    console.log(r8tingCriteriaOptionValue);
    if (r8tingCriteriaOptionId == id) {
      setR8tingCriteriaOptionId(null);
    } else {
      setR8tingCriteriaOptionId(id);
    }
  };
  const handleRatingCriteriaResult = async () => {
    if (r8tingCriteriaOptionValue < 8 || r8tingCriteriaOptionValue == 'NO') {
      if (!massage) {
        // Show toaster ....
        toast.error('Message is required', toastOptions);
        return;
      }
      if (images.length < 1) {
        // Show toaster ....
        toast.error('Atleast one image is required', toastOptions);
        return;
      }
    }

    setLoading(true);
    const latestImagesUrls = await handleUploadImage();
    apiProtected
      .post(`${ApiUrl.server_url}v1/rating-criteria-users`, {
        jobId: jobId,
        ratingCriteriaId: ratingCriteriaId,
        ratingCriteriaOptionId: r8tingCriteriaOptionId,
        damageIds: r8tingCriteriaDamageIds,
        criteriaInfo: massage,
        images: latestImagesUrls,
      })
      .then((res) => {
        setLoading(false);
        toast.success(`${criteriaListTitle} ${res.data.message}`, toastOptions);
        // setSaveButton(true);
        handleR8tingCategoryListUpdates();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        const errorMassage = error.response.data.message;
        toast.error(errorMassage, toastOptions);
      });
  };

  const handleUploadImage = async () => {
    const config = {
      bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
      dirName: `${process.env.REACT_APP_ENVIRONMENT}/job-ratings-media/photos`,
      region: process.env.REACT_APP_S3_REGION,
      accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    };

    try {
      let tempImagesArray = [];
      for (let i = 0; i < images.length; i++) {
        if (images[i].file) {
          let uploadRes = await S3FileUpload.uploadFile(images[i].file, config);
          tempImagesArray.push(uploadRes.location);
        } else if (images[i].url) {
          tempImagesArray.push(images[i].url);
        }
      }
      return tempImagesArray;
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Error in uploading files', toastOptions);
      return;
    }
  };

  return (
    <div>
      <ToastContainer />
      <div id="pills">
        <p
          className="Font_Family theme_text_color fw-bold"
          style={{ fontSize: '14px' }}
        >
          DAMAGE
        </p>
        <ul className="nav nav-pills">
          {damages?.length > 0 &&
            damages?.map((item) => (
              <li
                className={` rounded-pill CriteriaOption_titeltabs fw-bold ${
                  r8tingCriteriaDamageIds?.includes(item.id)
                    ? 'CriteriaOption_active'
                    : ''
                }`}
                key={item.id}
                onClick={() => {
                  handler8tingCriteriaDamageTab(item.id);
                }}
              >
                <a data-toggle="pill">{item.title}</a>
              </li>
            ))}
        </ul>
      </div>
      {/* </Grid> */}
      {/* </Grid>
      </TabsUnstyled> */}
      <div className="pills_ratingValue">
        <ul className="nav nav-pills">
          {ratingCriteriaOptions.length > 0 &&
            ratingCriteriaOptions.map((item) => (
              <li
                className={` pill CriteriaOption_ratingValue_tabs fw-bold ${
                  r8tingCriteriaOptionId == item.id
                    ? 'CriteriaOption_ratingValue_active'
                    : ''
                }`}
                key={item.id}
                onClick={() => {
                  handler8tingCriteriaOptionTab(item.id, item.ratingValue);
                }}
              >
                <a data-toggle="pill">{item.ratingValue}</a>
              </li>
            ))}
        </ul>
        {ratingCriteriaOptions.length > 0 &&
          ratingCriteriaOptions
            .filter((item) => item.id == r8tingCriteriaOptionId)
            .map((item) => (
              <p
                key={item.id}
                className="Font_Family theme_text_color mt-2 text-uppercase fw-bold"
                style={{ fontSize: '14px' }}
              >
                {item.ratingTitle}
              </p>
            ))}
      </div>
      <hr></hr>
      <div style={{ marginTop: '20px' }}>
        <p
          className="Font_Family theme_text_color fw-bold"
          style={{ fontSize: '14px' }}
        >
          UPLOAD PHOTO
        </p>
        <ImageUploading
          multiple
          acceptType={['jpg', 'gif', 'png', 'jpeg', 'tif', 'tiff']}
          value={images}
          onChange={onChange}
          // maxNumber={maxNumber}
          dataURLKey="url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemove,
            // onImageUpdate,
            // isDragging,
            // dragProps,
          }) => (
            // write your building UI

            <div className="criteriaOption_upload_image_wrapper">
              {/* </>
              )} */}
              &nbsp;
              <Grid container spacing={1} style={{ width: '100%' }}>
                <Grid item xs={6} sm={4} md={3} lg={3}>
                  <div
                    className="criteriaOption_image_upload_icon_div"
                    onClick={onImageUpload}
                  >
                    <img
                      src={image_upload_icon}
                      alt="icons"
                      style={{
                        width: '100%',
                        height: '120px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </Grid>
                <Gallery>
                  {imageList.map((image, index) => (
                    <Grid item xs={6} sm={4} md={3} lg={3} key={index}>
                      <div
                        key={index}
                        className="criteriaOption_image_upload_image_preview_div"
                      >
                        <Item
                          original={image['url']}
                          thumbnail={image['url']}
                          width="1024"
                          height="668"
                        >
                          {({ ref, open }) => (
                            <img
                              ref={ref}
                              onClick={open}
                              src={image['url']}
                              style={{
                                height: '120px',
                                width: '100%',
                                objectFit: 'cover',
                                // paddingRight: 10,
                                borderRadius: 10,
                                // borderTopRightRadius: 27,
                                // borderBottomRightRadius: 27,
                              }}
                            />
                          )}
                        </Item>
                        &nbsp;
                        {/* <div className="image-item-btn-wrapper"> */}
                        {/* {isReadonly != 1 && ( */}
                        <span
                          className="criteriaOption_image_delete_icons"
                          onClick={() =>
                            handleDeleteOrUpdate(onImageRemove, index)
                          }
                        >
                          <HighlightOffIcon
                            style={{
                              fontSize: '18px',
                              textAlign: 'center',
                              backgroundColor: 'white',
                              borderRadius: '25px',
                            }}
                          />
                        </span>
                        {/* )} */}
                        {/* </div> */}
                        {/* )} */}
                      </div>
                    </Grid>
                  ))}
                </Gallery>
              </Grid>
            </div>
          )}
        </ImageUploading>
      </div>
      <div className="criteriaOption_massage_div">
        <p
          className="Font_Family theme_text_color fw-bold"
          style={{ fontSize: '14px' }}
        >
          MESSAGE
        </p>
        <textarea
          required
          value={massage}
          placeholder="R8TING NOTES..."
          onChange={(e) => setMassage(e.target.value)}
          style={{ border: '1px solid #ebebeb', resize: 'none' }}
          className="silver_Color"
        ></textarea>
      </div>
      <div className="criteriaOption_button_div">
        <button
          type="button"
          className="btn  w-25 Font_Family"
          style={{
            height: '45px',
            borderRadius: '0px 25px 0px 25px',
            backgroundColor: '#e61d2a',
            color: 'white',
          }}
          // disabled={saveButton}
          disabled={!r8tingCriteriaOptionId}
          // onClick={handleUploadImage}
          onClick={handleRatingCriteriaResult}
        >
          <span className="d-flex justify-content-center align-content-center w-100">
            {loading ? <Loader /> : <span> SAVE</span>}
          </span>
        </button>
      </div>
    </div>
  );
}

export default CriteriaOption;
